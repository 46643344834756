import React from 'react';

const LinkedInIcon = (props) => (
  <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
    <path d="M22.23 0H1.77C.8 0 0 .77 0 1.72v20.56C0 23.2.8 24 1.77 24h20.45C23.2 24 24 23.2 24 22.28V1.72C24 .8 23.2 0 22.23 0zM7.12 20.45H3.56V9H7.1v11.45zm-1.78-12.8c-1.17 0-2.12-.97-2.12-2.15S4.2 3.3 5.35 3.3c1.18 0 2.13.96 2.13 2.14 0 1.18-.96 2.12-2.13 2.12zM20.45 20.45h-3.56v-5.6c0-1.33-.03-3.05-1.86-3.05-1.86 0-2.15 1.45-2.15 2.95v5.7h-3.56V9h3.42v1.57h.05c.47-.88 1.62-1.8 3.33-1.8 3.56 0 4.22 2.34 4.22 5.38v6.3z" />
  </svg>
);

export default function LinkedIn({ url }) {
  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="ml-2 text-gray-600 hover:text-gray-800"
      aria-label="LinkedIn"
    >
      <LinkedInIcon className="h-6 w-6" aria-hidden="true" />
    </a>
  );
}