import React from 'react';
import BackgroundGradient from '../ui/BackgroundGradient.jsx'; // Import the BackgroundGradient component

function DataPolicy() {
    return (
        <div className="relative isolate pt-14">
            {/* Use the BackgroundGradient component */}
            <BackgroundGradient />

            {/* Content */}
            <div className="py-24 px-2 md:px-14 lg:px-14">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl text-center">
                        <h1 className="text-4xl font-bold tracking-tight text-gray-900">
                            Data Privacy Policy
                        </h1>
                        <p className="mt-6 text-lg leading-8 text-gray-600">
                            This Data Privacy Policy was last updated on November 30th, 2024.
                        </p>
                    </div>

                    {/* Data Privacy Policy Sections */}
                    <div className="mt-10 space-y-8">
                        {/* Preamble */}
                        <section className="text-sm pt-4 px-6 border rounded-lg bg-white">
                            <h2 className="text-xl font-semibold text-[#8A79EC] mb-4">
                                Here’s what you need to know:
                            </h2>
                            <h3 className="text-lg font-semibold mb-2">Tarn Stores as Little Data as Possible</h3>
                            <p className="text-gray-700 mb-4">
                                Tarn is designed to store as little of your Salesforce data as possible. Most data is
                                stored temporarily (a maximum of 20 minutes) to deliver features, then permanently deleted.
                                The data we do retain is encrypted and stored in a dedicated database for each customer,
                                accessible only with encrypted credentials requiring both Salesforce and Tarn authentication.
                            </p>
                            <h3 className="text-lg font-semibold mb-2">Tarn is Not Developing Artificial Intelligence with Your Data</h3>
                            <p className="text-gray-700 mb-4">
                                To deliver our services, Tarn leverages OpenAI, which operates under a separate Data Policy
                                requiring your acceptance to use the application. Tarn itself will not use your Salesforce
                                data to develop artificial intelligence.
                            </p>
                            <h3 className="text-lg font-semibold mb-2">You Control Your Data</h3>
                            <p className="text-gray-700 mb-4">
                                When Tarn is not actively in use, we store only user-chosen Salesforce object configurations
                                and summaries of historical field updates, detailed below. You can delete this data within
                                the application at any time. Additionally, you can delete any stored data associated with
                                you, your organization, or your Salesforce at any time by contacting
                                <a className="text-[#8A79EC] underline" href="mailto:support@tarn.co"> support@tarn.co</a>.
                            </p>
                            {/* Summary of Data Storage */}
                            <h3 className="text-lg font-semibold mb-2">Summary of Data Storage</h3>
                            <ul className="list-disc pl-6 text-gray-700 mb-4">
                                <li className="mb-2">
                                    <strong>Salesforce Object Configuration Storage:</strong> Tarn stores Salesforce object structures
                                    (e.g., field names, configurations) chosen by you in the application. No object records
                                    are stored unless the application is actively in use.
                                </li>
                                <li className="mb-2">
                                    <strong>Temporary Record Storage (20 Minutes):</strong> When running a rule, only the relevant
                                    Salesforce records are stored temporarily (up to 20 minutes) for processing. These records
                                    are automatically deleted after the rule is completed or when they expire after 20 minutes.
                                </li>
                                <li>
                                    <strong>Audits:</strong> Tarn stores any Salesforce field updates made by the application on the Audit page.
                                    This includes:
                                </li>
                                <ul className="list-disc pl-6 text-gray-700 mb-4">
                                    <li className="mb-2 mt-2">
                                        The <strong>Salesforce ID</strong> of the record
                                    </li>
                                    <li className="mb-2">
                                       The  <strong>Salesforce API name </strong> of the updated field
                                    </li>
                                    <li className="mb-2 mt-2">
                                        The <strong>old value </strong>of the field
                                    </li>
                                    <li className="mb-2">
                                        The <strong>new value</strong> of the field
                                    </li>
                                </ul>
                            </ul>
                            <p className="mt-10 mb-2">Now... on to our formal Data Privacy Policy.</p>
                        </section>

                        {/* Section 1: Introduction */}
                        <section className="text-sm pt-4 px-6 border rounded-lg bg-white">
                            <h2 className="text-2xl font-semibold text-[#8A79EC] mb-4">1. Introduction</h2>
                            <p className="text-gray-700 mb-4">
                                This Data Privacy Policy outlines how Tarn collects, uses, and protects your data.
                                We are committed to transparency and limiting data retention to what is strictly necessary
                                for delivering our services. Tarn is designed to process your Salesforce data efficiently,
                                store minimal information, and empower you with full control over your data.
                            </p>
                            <p className="text-gray-700 mb-4">
                                Tarn collects certain data from your Salesforce instance and user accounts to provide our
                                services. This includes the configuration of selected Salesforce objects (fields and settings),
                                summaries of historical field updates, user details (such as name and email address), and
                                organization identifiers (like your Salesforce instance ID and organization name). Tarn does
                                not store full Salesforce records beyond a temporary 20-minute period while the app is actively
                                processing rules.
                            </p>
                            <p className="text-gray-700 mb-4">
                                This policy explains what data Tarn collects, why it is collected, how it is stored, and the
                                controls available to you for managing and deleting your data. For any questions or requests,
                                contact us at
                                <a className="text-[#8A79EC] underline" href="mailto:support@tarn.co"> support@tarn.co</a>.
                            </p>


                        </section>

                        {/* Section 2: Data Collection and Usage */}
                        <section className="text-sm pt-4 px-6 border rounded-lg bg-white">
                            <h2 className="text-2xl font-semibold text-[#8A79EC] mb-4">2. Data Collection and Usage</h2>

                            {/* 2.1 User Information */}
                            <h3 className="text-lg font-semibold mb-2">2.1 User Information</h3>
                            <p className="text-gray-700 mb-4">
                                <strong>What is collected:</strong> Tarn collects user names and email addresses when users
                                log in with Salesforce credentials. Tarn also stores the Salesforce instance ID and organization
                                name associated with your account.
                            </p>
                            <p className="text-gray-700 mb-4">
                                <strong>Why it is collected:</strong> This information is used to authenticate users,
                                associate them with their Salesforce instance, and enable secure access to Tarn’s services.
                            </p>

                            {/* 2.2 Salesforce Object Configurations */}
                            <h3 className="text-lg font-semibold mb-2">2.2 Salesforce Object Configurations</h3>
                            <p className="text-gray-700 mb-4">
                                <strong>What is collected:</strong> Tarn stores selected Salesforce object configurations,
                                including field names, settings, and other metadata chosen by users within the app.
                            </p>
                            <p className="text-gray-700 mb-4">
                                <strong>Why it is collected:</strong> This data is necessary to power Tarn’s features,
                                such as rule creation and fix processing. It ensures efficient and accurate functionality
                                while minimizing the need to access full Salesforce records.
                            </p>

                            {/* 2.3 Temporary Salesforce Record Storage */}
                            <h3 className="text-lg font-semibold mb-2">2.3 Temporary Salesforce Record Storage</h3>
                            <p className="text-gray-700 mb-4">
                                <strong>What is collected:</strong> Tarn temporarily stores Salesforce records relevant
                                to rule runs and fix processing. These records include the data required to identify and fix
                                issues within your Salesforce instance.
                            </p>
                            <p className="text-gray-700 mb-4">
                                <strong>Why it is collected:</strong> This temporary storage is required to process rules
                                and enable issue identification and resolution. Records are automatically deleted after a
                                maximum of 20 minutes.
                            </p>

                            {/* 2.4 Audits */}
                            <h3 className="text-lg font-semibold mb-2">2.4 Audits</h3>
                            <p className="text-gray-700 mb-4">
                                <strong>What is collected:</strong> When any Salesforce field updates are made via Tarn,
                                the following data is stored:
                            </p>
                            <ul className="list-disc pl-6 text-gray-700 mb-4">
                                <li>The Salesforce ID of the record</li>
                                <li>The Salesforce API name of the updated field</li>
                                <li>The old value of the field</li>
                                <li>The new value of the field</li>
                            </ul>
                            <p className="text-gray-700 mb-4">
                                <strong>Why it is collected:</strong> This data is retained for accountability and historical review.
                                It is visible on the Audit page and can be downloaded or deleted by users at any time.
                            </p>

                            {/* 2.5 Interaction with OpenAI */}
                            <h3 className="text-lg font-semibold mb-2">2.5 Interaction with OpenAI</h3>
                            <p className="text-gray-700 mb-4">
                                <strong>What is collected:</strong> Salesforce object configurations and specific rule or record data
                                may be passed to OpenAI for processing when leveraging AI-powered features.
                            </p>
                            <p className="text-gray-700 mb-4">
                                <strong>Why it is collected:</strong> This record data is required for OpenAI to identify and fix issues.
                                Data is only passed to OpenAI when identifying or fixing the issue requires artificial intelligence
                                to determine the problem and/or solution with the record. OpenAI operates under its own Data Policy.
                            </p>
                        </section>

                                                {/* Section 3: Data Retention Policy */}
                                                <section className="text-sm pt-4 px-6 border rounded-lg bg-white">
                            <h2 className="text-2xl font-semibold text-[#8A79EC] mb-4">3. Data Retention Policy</h2>
                            <p className="text-gray-700 mb-4">
                                Tarn is committed to minimizing data retention to only what is necessary for providing its services.
                                The following outlines how data is stored, how long it is retained, and the options users have for managing or deleting their data.
                            </p>

                            {/* 3.1 User Information */}
                            <h3 className="text-lg font-semibold mb-2">3.1 User Information</h3>
                            <p className="text-gray-700 mb-4">
                                <strong>Retention Period:</strong> User names, email addresses, Salesforce instance IDs, and organization
                                names are stored as long as the account is active. This information is deleted when the user requests account
                                or organization deletion.
                            </p>
                            <p className="text-gray-700 mb-4">
                                <strong>Reason for Retention:</strong> This information is necessary for authenticating users, associating them
                                with their Salesforce instance, and ensuring secure access to the application.
                            </p>
                            <p className="text-gray-700 mb-4">
                                <strong>User Controls:</strong> Users can request deletion of their account data by contacting 
                                <a className="text-[#8A79EC] underline" href="mailto:support@tarn.co"> support@tarn.co</a>.
                            </p>

                            {/* 3.2 Salesforce Object Configurations */}
                            <h3 className="text-lg font-semibold mb-2">3.2 Salesforce Object Configurations</h3>
                            <p className="text-gray-700 mb-4">
                                <strong>Retention Period:</strong> Salesforce object configurations (e.g., field names and settings) are stored indefinitely, or until the user deletes this data within the application.
                            </p>
                            <p className="text-gray-700 mb-4">
                                <strong>Reason for Retention:</strong> This data is retained to enable Tarn to deliver efficient and accurate functionality for its features, such as rule creation, fix creation, and resolving data issues in the user’s Salesforce instance.
                            </p>
                            <p className="text-gray-700 mb-4">
                                <strong>User Controls:</strong> Users can delete Salesforce object configurations within the app at any time.
                            </p>

                            {/* 3.3 Temporary Salesforce Record Storage */}
                            <h3 className="text-lg font-semibold mb-2">3.3 Temporary Salesforce Record Storage</h3>
                            <p className="text-gray-700 mb-4">
                                <strong>Retention Period:</strong> Salesforce records relevant to rule runs or fix processing are stored temporarily for a maximum of 20 minutes. These records are automatically deleted after the run is completed or after 20 minutes, whichever comes first.
                            </p>
                            <p className="text-gray-700 mb-4">
                                <strong>Reason for Retention:</strong> Temporary storage is essential for processing rules and enabling Tarn to identify and resolve issues in your Salesforce data.
                            </p>
                            <p className="text-gray-700 mb-4">
                                <strong>User Controls:</strong> No user action is needed for deletion, as records are deleted automatically.
                            </p>

                            {/* 3.4 Audits */}
                            <h3 className="text-lg font-semibold mb-2">3.4 Audits</h3>
                            <p className="text-gray-700 mb-4">
                                <strong>Retention Period:</strong> Audit logs of Salesforce field updates are stored indefinitely or until deleted by the user.
                            </p>
                            <p className="text-gray-700 mb-4">
                                <strong>Reason for Retention:</strong> Audit logs provide accountability and historical review of field changes made via Tarn, including the Salesforce record ID, the Salesforce API field name, and its old and new values.
                            </p>
                            <p className="text-gray-700 mb-4">
                                <strong>User Controls:</strong> Users can review, download as a CSV, or delete these logs directly from the Audit page in the application.
                            </p>

                            {/* 3.5 Interaction with OpenAI */}
                            <h3 className="text-lg font-semibold mb-2">3.5 Interaction with OpenAI</h3>
                            <p className="text-gray-700 mb-4">
                                <strong>Retention Period:</strong> Data passed to OpenAI during processing is not stored by Tarn. OpenAI may process this data temporarily in accordance with its own Data Policy.
                            </p>
                            <p className="text-gray-700 mb-4">
                                <strong>Reason for Retention:</strong> Data is sent to OpenAI solely to enable AI-powered features, such as generating fixes for Salesforce issues.
                            </p>
                            <p className="text-gray-700 mb-4">
                                <strong>User Controls:</strong> Users agree to OpenAI’s Data Policy when using Tarn’s AI features. No additional data storage controls are applicable within Tarn.
                            </p>

                            {/* 3.6 Deletion Requests */}
                            <h3 className="text-lg font-semibold mb-2">3.6 Deletion Requests</h3>
                            <p className="text-gray-700 mb-4">
                                <strong>User-Initiated Deletion:</strong> Users can delete any data stored in the application, including Salesforce object configurations and field update audits, directly through the app interface or by contacting 
                                <a className="text-[#8A79EC] underline" href="mailto:support@tarn.co"> support@tarn.co</a>.
                            </p>
                            <p className="text-gray-700 mb-4">
                                <strong>Automatic Deletion:</strong> Temporary Salesforce records are deleted automatically after 20 minutes, without requiring user intervention.
                            </p>
                            <p className="text-gray-700 mb-4">
                                <strong>Data Retained After Account Termination:</strong> No data is retained after account termination. All data associated with the user, the organization, the Salesforce instance, and any Salesforce data is deleted permanently and cannot be recovered.
                            </p>
                        </section>

                                                {/* Section 4: Data Security */}
                                                <section className="text-sm pt-4 px-6 border rounded-lg bg-white">
                            <h2 className="text-2xl font-semibold text-[#8A79EC] mb-4">4. Data Security</h2>
                            <p className="text-gray-700 mb-4">
                                Tarn takes data security seriously and implements robust measures to protect user and
                                organizational data. Our goal is to ensure the confidentiality, integrity, and availability
                                of your data while maintaining transparency about our practices.
                            </p>

                            {/* 4.1 Encryption */}
                            <h3 className="text-lg font-semibold mb-2">4.1 Encryption</h3>
                            <p className="text-gray-700 mb-4">
                                All data stored in Tarn, including Salesforce object configurations and audit logs, is encrypted
                                at rest and in transit using industry-standard encryption protocols. Temporary Salesforce
                                records are encrypted during processing and are automatically deleted after a maximum of 20 minutes.
                            </p>

                            {/* 4.2 Access Controls */}
                            <h3 className="text-lg font-semibold mb-2">4.2 Access Controls</h3>
                            <p className="text-gray-700 mb-4">
                                Tarn enforces strict access controls to ensure that only authorized users with Salesforce
                                and Tarn authentication can access data. Each customer’s data is stored in a dedicated database,
                                accessible only with encrypted credentials specific to their account. Tarn employees have
                                limited access to customer data and only as required to resolve specific support or technical issues.
                            </p>

                            {/* 4.3 Isolation of Customer Data */}
                            <h3 className="text-lg font-semibold mb-2">4.3 Isolation of Customer Data</h3>
                            <p className="text-gray-700 mb-4">
                                Data for each customer is stored in isolated databases to prevent cross-access between organizations.
                                Data isolation is designed to enhance security and privacy, ensuring that each customer’s data
                                remains separate and secure.
                            </p>

                            {/* 4.4 Data Minimization */}
                            <h3 className="text-lg font-semibold mb-2">4.4 Data Minimization</h3>
                            <p className="text-gray-700 mb-4">
                                Tarn minimizes the amount of data stored by limiting retention to what is necessary for providing services.
                                No full Salesforce records are stored beyond the temporary fix process, which lasts a maximum of 20 minutes.
                                Salesforce field update audits store only the necessary data to reverse any unintentional changes.
                            </p>

                            {/* 4.5 Monitoring and Auditing */}
                            <h3 className="text-lg font-semibold mb-2">4.5 Monitoring and Auditing</h3>
                            <p className="text-gray-700 mb-4">
                                Tarn monitors its systems for unauthorized access and unusual activity, using logging and auditing
                                tools to identify and respond to security risks. Regular audits and penetration testing are conducted
                                to ensure system vulnerabilities are identified and addressed promptly.
                            </p>

                            {/* 4.6 Third-Party Security */}
                            <h3 className="text-lg font-semibold mb-2">4.6 Third-Party Security</h3>
                            <p className="text-gray-700 mb-4">
                                Tarn uses trusted third-party services (e.g., Salesforce API, OpenAI) with their own stringent security measures.
                                Data shared with third parties is limited to what is necessary for providing services and is subject
                                to their respective data security policies.
                            </p>

                            {/* 4.7 Incident Response */}
                            <h3 className="text-lg font-semibold mb-2">4.7 Incident Response</h3>
                            <p className="text-gray-700 mb-4">
                                In the unlikely event of a data breach, Tarn will notify affected users promptly and take corrective
                                action to resolve the issue.
                            </p>

                            <p className="text-gray-700 mb-4">
                                By implementing these security measures, Tarn ensures that user and organizational data is protected
                                from unauthorized access, loss, or misuse. If you have questions or concerns about our data security
                                practices, please contact us at 
                                <a className="text-[#8A79EC] underline" href="mailto:support@tarn.co"> support@tarn.co</a>.
                            </p>
                        </section>

                                                {/* Section 5: User Rights and Controls */}
                                                <section className="text-sm pt-4 px-6 border rounded-lg bg-white">
                            <h2 className="text-2xl font-semibold text-[#8A79EC] mb-4">5. User Rights and Controls</h2>
                            <p className="text-gray-700 mb-4">
                                Tarn is committed to providing users with full control over their data. The following outlines your rights and the controls available within the application:
                            </p>

                            {/* 5.1 Deleting Data */}
                            <h3 className="text-lg font-semibold mb-2">5.1 Deleting Data</h3>
                            <p className="text-gray-700 mb-4">
                                <strong>Audit Logs:</strong> Users can delete field update audit logs directly from the Audit page within the application. Once deleted, these logs are permanently removed and cannot be recovered.
                            </p>
                            <p className="text-gray-700 mb-4">
                                <strong>Salesforce Object Configurations:</strong> Users can delete stored object configurations (e.g., field names and settings) in the “Add Salesforce Object” interface at any time.
                            </p>
                            <p className="text-gray-700 mb-4">
                                <strong>Account and Organization Data:</strong> Users can request the deletion of their account, Salesforce organization data, and any related stored data by contacting 
                                <a className="text-[#8A79EC] underline" href="mailto:support@tarn.co"> support@tarn.co</a>. Tarn will process these requests promptly, and all associated data will be permanently deleted.
                            </p>

                            {/* 5.2 Downloading Data */}
                            <h3 className="text-lg font-semibold mb-2">5.2 Downloading Data</h3>
                            <p className="text-gray-700 mb-4">
                                <strong>Audit Logs as CSV Files:</strong> Users can download field update audit logs at any time from the Audit page in CSV format. This provides transparency for any field changes processed via Tarn.
                            </p>

                            {/* 5.3 Data Review and Updates */}
                            <h3 className="text-lg font-semibold mb-2">5.3 Data Review and Updates</h3>
                            <p className="text-gray-700 mb-4">
                                <strong>Object Configurations:</strong> Users can review and update stored Salesforce object configurations in the application.
                            </p>
                            <p className="text-gray-700 mb-4">
                                <strong>Account Information:</strong> Users can update their account information (e.g., name, email) directly within the app or by contacting 
                                <a className="text-[#8A79EC] underline" href="mailto:support@tarn.co"> support@tarn.co</a>.
                            </p>

                            {/* 5.4 Retention and Expiration */}
                            <h3 className="text-lg font-semibold mb-2">5.4 Retention and Expiration</h3>
                            <p className="text-gray-700 mb-4">
                                <strong>Retention:</strong> Temporary Salesforce record data is automatically deleted after 20 minutes, requiring no user intervention.
                            </p>
                            <p className="text-gray-700 mb-4">
                                <strong>Data Deletion:</strong> Data retained for audit purposes (e.g., field updates) can be deleted by users at any time.
                            </p>

                            <p className="text-gray-700 mb-4">
                                By offering these controls, Tarn ensures users maintain ownership of their data while fostering transparency and ease of management.
                            </p>
                        </section>

                                                {/* Section 6: Third-Party Access */}
                                                <section className="text-sm pt-4 px-6 border rounded-lg bg-white">
                            <h2 className="text-2xl font-semibold text-[#8A79EC] mb-4">6. Third-Party Access</h2>
                            <p className="text-gray-700 mb-4">
                                Tarn does not share user data with third parties except when required by law or necessary for providing its services. 
                                The following describes how third parties may interact with your data:
                            </p>

                            {/* 6.1 Salesforce API */}
                            <h3 className="text-lg font-semibold mb-2">6.1 Salesforce API</h3>
                            <p className="text-gray-700 mb-4">
                                Tarn integrates with the Salesforce API to retrieve and process user-selected data for rule creation, fix creation, 
                                issue identification, and field updates. Only the specific data required for the user’s operation is requested 
                                from or transmitted to Salesforce.
                            </p>

                            {/* 6.2 OpenAI API */}
                            <h3 className="text-lg font-semibold mb-2">6.2 OpenAI API</h3>
                            <p className="text-gray-700 mb-4">
                                Tarn leverages the OpenAI API to provide AI-powered features, such as creating rules, creating fixes, or fixing 
                                Salesforce data issues. Data passed to OpenAI includes Salesforce object configurations and relevant record data 
                                necessary for processing. OpenAI operates under its own Data Policy, and users must agree to this policy when using Tarn.
                            </p>

                            {/* 6.3 Trusted Third-Party Services */}
                            <h3 className="text-lg font-semibold mb-2">6.3 Trusted Third-Party Services</h3>
                            <p className="text-gray-700 mb-4">
                                Tarn may use third-party tools (e.g., cloud hosting, monitoring) to deliver its services. All third-party providers 
                                used by Tarn, including cloud hosting and monitoring services, implement stringent security measures, including encryption 
                                and access controls, to protect your data.
                            </p>

                            {/* 6.4 Legal Requirements */}
                            <h3 className="text-lg font-semibold mb-2">6.4 Legal Requirements</h3>
                            <p className="text-gray-700 mb-4">
                                Tarn may disclose user data if required by law or in response to valid legal requests, such as subpoenas or court orders.
                            </p>

                            <p className="text-gray-700 mb-4">
                                Tarn takes every measure to limit the data shared with third parties to what is strictly necessary. If you have questions 
                                about third-party data access, contact 
                                <a className="text-[#8A79EC] underline" href="mailto:support@tarn.co"> support@tarn.co</a>.
                            </p>
                        </section>

                        {/* Section 7: Compliance and Transparency */}
                        <section className="text-sm pt-4 px-6 border rounded-lg bg-white">
                            <h2 className="text-2xl font-semibold text-[#8A79EC] mb-4">7. Compliance and Transparency</h2>
                            <p className="text-gray-700 mb-4">
                                Tarn is committed to complying with all applicable data privacy laws and regulations while maintaining transparency about its data practices. 
                                Our approach ensures that users and organizations understand how their data is handled and retained.
                            </p>

                            {/* GDPR */}
                            <h3 className="text-lg font-semibold mb-2">General Data Protection Regulation (GDPR)</h3>
                            <p className="text-gray-700 mb-4">
                                Tarn adheres to GDPR principles, ensuring that EU users have access to their data, can request deletion, and are informed about how their 
                                data is used. Tarn only processes data with the user’s explicit consent, such as agreeing to Tarn’s Data Privacy Policy and OpenAI’s Data Policy.
                            </p>

                            {/* CCPA */}
                            <h3 className="text-lg font-semibold mb-2">California Consumer Privacy Act (CCPA)</h3>
                            <p className="text-gray-700 mb-4">
                                Tarn complies with CCPA by allowing California users to request access to, deletion of, or information about their data. 
                                Tarn does not sell user data to third parties.
                            </p>

                            {/* Other Applicable Laws */}
                            <h3 className="text-lg font-semibold mb-2">Other Applicable Laws</h3>
                            <p className="text-gray-700 mb-4">
                                Tarn complies with local and international data privacy laws relevant to its services and the regions it operates in.
                            </p>
                        </section>

                        {/* Section 8: Contact Information */}
                        <section className="text-sm pt-4 px-6 border rounded-lg bg-white">
                            <h2 className="text-2xl font-semibold text-[#8A79EC] mb-4">8. Contact Information</h2>
                            <p className="text-gray-700 mb-4">
                                For any questions, concerns, or requests related to this Data Privacy Policy or Tarn’s data practices, please contact us using the information below:
                            </p>
                            <p className="text-gray-700 mb-4">
                                <strong>Support Email:</strong> 
                                <a className="text-[#8A79EC] underline" href="mailto:support@tarn.co"> support@tarn.co</a>
                            </p>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DataPolicy;