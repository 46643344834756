import { ChevronRightIcon } from '@heroicons/react/20/solid';
import Step1Rules from './how-it-works/Step1Rules.jsx';
import Step2Fixes from './how-it-works/Step2Fixes.jsx';
import TarnDataBento from './how-it-works/TarnDataBento.jsx';

export default function HowItWorks() {
    return (
        <div id="how-it-works" className="relative isolate overflow-hidden">
            {/* Centered "How it works" section */}
            <div className="flex flex-col items-center justify-center pt-10">
                <div className="mx-auto max-w-7xl px-6 text-center">
                    <h2 className="text-4xl font-semibold tracking-tight text-gray-900 sm:text-6xl">
                        How it works
                    </h2>
                    <p className="mt-8 text-lg font-medium text-gray-500 sm:text-xl flex flex-col sm:flex-row sm:gap-x-4 items-center justify-center">
                        <span className="block sm:mb-0 mb-2">Set up is easy.</span>
                        <button
                            onClick={() => (window.location.href = 'https://app.tarn.co')}
                            className="flex items-center gap-3 rounded-md bg-white px-3 py-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            style={{ maxWidth: '300px' }}
                        >
                            {/* Salesforce Icon */}
                            <img
                                src="/assets/Salesforce_Corporate_Logo_RGB.png"
                                alt="Salesforce Logo"
                                className="h-5 w-auto ml-1"
                            />
                            <span>Login with Salesforce</span>
                        </button>
                        <span className="block mt-2 sm:mt-0">
                            and <span className="text-indigo-500 font-bold">go.</span>
                        </span>
                    </p>
                </div>
            </div>

            {/* Step1Rules positioned normally */}
            <div className="px-6">
                <Step1Rules />
                <Step2Fixes />
                <TarnDataBento />
            </div>
        </div>
    );
}