import React from 'react';
import BackgroundGradient from '../ui/BackgroundGradient.jsx'; // Import the BackgroundGradient component

function TermsConditions() {
    return (
        <div className="relative isolate pt-14">
            {/* Use the BackgroundGradient component */}

            <BackgroundGradient />

            {/* Content */}
            <div className="py-24 px-2 md:px-14 lg:px-14">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl text-center">
                        <h1 className="text-4xl font-bold tracking-tight text-gray-900">
                            Terms & Conditions
                        </h1>
                        <p className="mt-6 text-lg leading-8 text-gray-600">
                            Please review our Terms and Conditions.
                        </p>
                    </div>

                    {/* Terms and Conditions Sections */}
                    <div className="mt-10 space-y-8">
                        {/* Intro */}
                        <section className="text-sm pt-4 px-6 border rounded-lg bg-white">
                            <h2 className="text-2xl font-semibold text-[#8A79EC] mb-4">Introduction</h2>
                            <p className="text-gray-700 mb-4">
                                Welcome to Tarn! Tarn offers a range of software services that enable CRM users to analyze, fix, and optimize CRM data (collectively, the “Platform Services”). The following terms and conditions (collectively with any mutually agreed Addenda, this “Agreement”) apply to your use of the Platform Services. No other terms will apply unless expressly agreed in writing by the parties.
                            </p>
                            <p className="text-gray-700 mb-4">
                                “Tarn” refers to the entity providing the Platform Services, while you, as the user, are referred to below as the “Customer.” You and Tarn hereby agree as follows:
                            </p>
                        </section>

                        {/* Section 1: Tarn Services */}
                        <section className="text-sm pt-4 px-6 border rounded-lg bg-white">
                            <h2 className="text-2xl font-semibold text-[#8A79EC] mb-4">1. Tarn Services</h2>
                            <h3 className="text-lg font-semibold mb-2">1.1 Provision of Services</h3>
                            <p className="text-gray-700 mb-4">
                                Tarn will provide the Platform Services, including application programming interfaces, connectors, and other tools that enable the Customer to access and utilize the Platform Services, in accordance with the terms of this Agreement. Tarn will take reasonable steps to maintain and support the Platform Services to ensure reliable access for the Customer. Tarn may update or enhance the Platform Services periodically to improve functionality, security, or performance.
                            </p>
                            <h3 className="text-lg font-semibold mb-2">1.2 Customer Data</h3>
                            <p className="text-gray-700 mb-4">
                                Tarn will maintain appropriate administrative, technical, organizational, and physical security measures to protect the Customer’s CRM data and other data uploaded to the Platform Services (“Customer Data”) against unauthorized access, disclosure, or alteration. Tarn will use reasonable efforts to protect the Customer Data against the introduction of any malicious code, including viruses, worms, or other harmful components. Tarn will use and process Customer Data solely to provide the Platform Services. Customer retains all rights to its data, and Tarn will use Customer Data only to the extent necessary to deliver the services. Customer is responsible for maintaining all rights required to provide the Customer Data to Tarn and use it within the Platform Services. Tarn may remove Customer Data if required by law or based on a credible third-party claim after providing reasonable notice to the Customer.
                            </p>
                        </section>

                        {/* Section 2: Customer Commitments */}
                        <section className="text-sm pt-4 px-6 border rounded-lg bg-white">
                            <h2 className="text-2xl font-semibold text-[#8A79EC] mb-4">2. Customer Commitments</h2>
                            <h3 className="text-lg font-semibold mb-2">2.1 Use of the Platform Services</h3>
                            <p className="text-gray-700 mb-4">
                                Customer agrees to use the Platform Services only as permitted by this Agreement and any provided documentation or reasonable written instructions from Tarn (“Documentation”). Customer will not use the Platform Services in any way that could disrupt, disable, overburden, or impair the functionality of the Platform Services or interfere with any other party’s use of the Platform Services.
                            </p>
                            <h3 className="text-lg font-semibold mb-2">2.2 Customer Account</h3>
                            <p className="text-gray-700 mb-4">
                                Customer will ensure that all account information is accurate and complete and will promptly update any information that becomes inaccurate. Customer is responsible for maintaining the security and confidentiality of account credentials and is solely responsible for any activity under the account. Customer will restrict access to authorized users (“Authorized Users”) and ensure that each Authorized User has unique credentials. Customer acknowledges that any sharing of credentials may result in loss of access to the Platform Services. Customer will promptly notify Tarn of any unauthorized access or security breaches affecting its account.
                            </p>
                            <h3 className="text-lg font-semibold mb-2">2.3 Compliance and Conduct</h3>
                            <p className="text-gray-700 mb-4">
                                Customer agrees not to use the Platform Services for any activities that: (a) violate any applicable laws, regulations, or third-party rights, including privacy and intellectual property rights; (b) attempt to gain unauthorized access to Tarn’s systems or data, or compromise the security of the Platform Services; or (c) introduce any harmful code, such as viruses, malware, or other malicious components. Customer also agrees not to transfer, resell, or license the Platform Services or make them available to any third parties other than Authorized Users.
                            </p>
                        </section>

                        {/* Section 3: Payment */}
                        <section className="text-sm pt-4 px-6 border rounded-lg bg-white">
                            <h2 className="text-2xl font-semibold text-[#8A79EC] mb-4">3. Payment</h2>
                            <h3 className="text-lg font-semibold mb-2">3.1 Fees and Payment Terms</h3>
                            <p className="text-gray-700 mb-4">
                                Customer will pay the fees associated with the Platform Services as outlined on Tarn’s website or any relevant pricing documentation provided to the Customer (the “Fees”). All Fees are non-refundable except as required by applicable law. Tarn may charge the Customer’s payment method on file according to the agreed billing schedule. Customer is responsible for keeping payment information accurate and up-to-date.
                            </p>
                            <h3 className="text-lg font-semibold mb-2">3.2 Taxes</h3>
                            <p className="text-gray-700 mb-4">
                                All Fees are exclusive of applicable taxes, levies, or duties imposed by taxing authorities. Customer is responsible for paying any applicable taxes, excluding taxes based on Tarn’s income. If required by law, Tarn may charge applicable taxes in addition to the Fees.
                            </p>
                            <h3 className="text-lg font-semibold mb-2">3.3 Late Payments and Suspension</h3>
                            <p className="text-gray-700 mb-4">
                                If any Fees remain unpaid past the due date, Tarn reserves the right to suspend Customer’s access to the Platform Services until such Fees are paid in full. Tarn may also charge interest on overdue payments at the rate of one percent (1%) per month, or the maximum rate permitted by law, whichever is lower. Customer will be responsible for any reasonable collection costs and fees Tarn incurs as a result of overdue payments.
                            </p>
                        </section>

                        {/* Section 4: Confidentiality */}
                        <section className="text-sm pt-4 px-6 border rounded-lg bg-white">
                            <h2 className="text-2xl font-semibold text-[#8A79EC] mb-4">4. Confidentiality</h2>
                            <h3 className="text-lg font-semibold mb-2">4.1 Definitions</h3>
                            <p className="text-gray-700 mb-4">
                                “Confidential Information” refers to any non-public information disclosed by one party (“Disclosing Party”) to the other (“Receiving Party”) that is identified as confidential or that a reasonable person would understand to be confidential based on the nature of the information and the circumstances of its disclosure. Customer’s Confidential Information includes data uploaded to the Platform Services. Tarn’s Confidential Information includes the Platform Services’ underlying technology, pricing, and documentation. Confidential Information does not include information that: (a) becomes public through no fault of the Receiving Party; (b) was known to the Receiving Party prior to disclosure; (c) is received from a third party without breach of any obligation of confidentiality; or (d) is independently developed without reference to the Disclosing Party’s Confidential Information.
                            </p>
                            <h3 className="text-lg font-semibold mb-2">4.2 Obligations</h3>
                            <p className="text-gray-700 mb-4">
                                The Receiving Party will only use Confidential Information for purposes related to this Agreement and will restrict access to Confidential Information to employees, contractors, and agents who need to know it for those purposes and are bound by confidentiality obligations. The Receiving Party will use reasonable care to protect the Confidential Information and prevent unauthorized access or disclosure. Confidential Information remains the property of the Disclosing Party, and upon written request, the Receiving Party will destroy or return all Confidential Information in its possession, except for backup copies made in the ordinary course of business.
                            </p>
                            <h3 className="text-lg font-semibold mb-2">4.3 Required Disclosures</h3>
                            <p className="text-gray-700 mb-4">
                                If the Receiving Party is legally required to disclose any Confidential Information, it will provide prompt written notice to the Disclosing Party (if legally permissible) and will cooperate with any efforts to seek protective measures at the Disclosing Party’s expense.
                            </p>
                            <h3 className="text-lg font-semibold mb-2">4.4 Data Privacy</h3>
                            <p className="text-gray-700 mb-4">
                                Tarn’s handling of Customer Data is governed by its Data Privacy Policy, which outlines how data is collected, used, shared, and protected. Customer acknowledges and agrees to the terms of the Data Privacy Policy as a condition of using the Platform Services.
                            </p>
                        </section>

                        {/* Section 5: Indemnification */}
                        <section className="text-sm pt-4 px-6 border rounded-lg bg-white">
                            <h2 className="text-2xl font-semibold text-[#8A79EC] mb-4">5. Indemnification</h2>

                            <h3 className="text-lg font-semibold mb-2">5.1 Indemnification by Tarn</h3>
                            <p className="text-gray-700 mb-4">
                                Tarn agrees to defend, indemnify, and hold the Customer harmless against any claims, damages, losses, liabilities, and expenses (including reasonable legal fees) arising from any third-party claim that the Customer’s use of the Platform Services as permitted under this Agreement infringes or misappropriates a third party’s intellectual property rights or results from Tarn’s gross negligence or willful misconduct.
                            </p>

                            <h3 className="text-lg font-semibold mb-2">5.2 Indemnification by Customer</h3>
                            <p className="text-gray-700 mb-4">
                                Customer agrees to defend, indemnify, and hold Tarn harmless against any claims, damages, losses, liabilities, and expenses (including reasonable legal fees) arising from: (a) Customer’s violation of any laws or third-party rights, including intellectual property or privacy rights; or (b) any use of the Platform Services by Customer that violates this Agreement or applicable law.
                            </p>

                            <h3 className="text-lg font-semibold mb-2">5.3 Indemnification Process</h3>
                            <p className="text-gray-700 mb-4">
                                A party seeking indemnification (“Indemnified Party”) will promptly notify the other party (“Indemnifying Party”) of any claim. The Indemnifying Party will have control over the defense and settlement of the claim, provided that it does not settle any claim that imposes liability or obligations on the Indemnified Party without prior consent. The Indemnified Party will cooperate in the defense at the Indemnifying Party’s expense.
                            </p>
                        </section>

                        {/* Section 6: Disclaimer */}
                        <section className="text-sm pt-4 px-6 border rounded-lg bg-white">
                            <h2 className="text-2xl font-semibold text-[#8A79EC] mb-4">6. Disclaimer</h2>
                            <p className="text-gray-700 mb-4">
                                Except as expressly provided in this Agreement, the Platform Services and any related products or services are provided on an “AS IS” and “AS AVAILABLE” basis. Tarn expressly disclaims all warranties, whether express, implied, or statutory, including but not limited to warranties of merchantability, fitness for a particular purpose, non-infringement, and any warranties arising out of course of dealing or usage of trade. Tarn does not guarantee that the Platform Services will be uninterrupted, error-free, or meet Customer’s specific needs. Customer is solely responsible for backing up all Customer Data, and Tarn will not be liable for any loss of Customer Data. Additionally, Tarn reserves the right to modify or improve features, functions, or interfaces of the Platform Services over time, provided that such changes do not materially degrade the Platform Services.
                            </p>
                        </section>

                        {/* Section 7: Limitation of Liability */}
                        <section className="text-sm pt-4 px-6 border rounded-lg bg-white">
                            <h2 className="text-2xl font-semibold text-[#8A79EC] mb-4">7. Limitation of Liability</h2>
                            <p className="text-gray-700 mb-4">
                                To the maximum extent permitted by law, in no event will either party be liable to the other for any indirect, special, incidental, consequential, or punitive damages, including but not limited to loss of profits, loss of business, or loss of data, even if the party has been advised of the possibility of such damages. This limitation applies regardless of the basis of liability, including tort, contract, or other legal theories.
                            </p>
                            <p className="text-gray-700 mb-4">
                                Except for each party’s indemnification obligations, Customer’s payment obligations, or Customer’s breach of Section 2.3 (Compliance and Conduct) or Section 4 (Confidentiality), each party’s total cumulative liability arising out of or relating to this Agreement will not exceed the total Fees paid by Customer to Tarn in the twelve (12) months preceding the claim. The provisions of this section allocate the risks under this Agreement, and the parties have relied on these limitations in determining whether to enter into this Agreement.
                            </p>
                        </section>

                        {/* Section 8: Term and Termination */}
                        <section className="text-sm pt-4 px-6 border rounded-lg bg-white">
                            <h2 className="text-2xl font-semibold text-[#8A79EC] mb-4">8. Term and Termination</h2>

                            <h3 className="text-lg font-semibold mb-2">8.1 Term</h3>
                            <p className="text-gray-700 mb-4">
                                This Agreement will commence on the date the Customer first accesses the Platform Services (the “Effective Date”) and will continue until terminated in accordance with this Section. The term of any paid subscription or service will renew automatically based on the selected billing cycle, unless either party provides written notice of non-renewal at least thirty (30) days before the end of the current term.
                            </p>

                            <h3 className="text-lg font-semibold mb-2">8.2 Termination for Cause</h3>
                            <p className="text-gray-700 mb-4">
                                Either party may terminate this Agreement or suspend access to the Platform Services upon written notice if the other party materially breaches any term of this Agreement and fails to cure the breach within thirty (30) days of receiving notice of the breach. Additionally, Tarn may terminate this Agreement immediately upon notice if required to comply with applicable laws or if Customer is using the Platform Services in a manner that could harm Tarn’s business or reputation.
                            </p>

                            <h3 className="text-lg font-semibold mb-2">8.3 Suspension of Services</h3>
                            <p className="text-gray-700 mb-4">
                                Tarn may suspend Customer’s access to the Platform Services if Customer fails to pay any undisputed Fees within thirty (30) days of the due date or if Customer is in material breach of this Agreement. Tarn will provide reasonable notice before suspending access, and will promptly restore access upon resolution of the issue.
                            </p>

                            <h3 className="text-lg font-semibold mb-2">8.4 Effect of Termination</h3>
                            <p className="text-gray-700 mb-4">
                                Upon termination of this Agreement, Customer’s access to the Platform Services will immediately cease. Tarn will delete Customer Data within a reasonable period following termination, subject to any applicable legal obligations to retain certain information.
                            </p>

                            <h3 className="text-lg font-semibold mb-2">8.5 Survival</h3>
                            <p className="text-gray-700 mb-4">
                                The provisions of Sections 2 (Customer Commitments), 3 (Payment), 4 (Confidentiality), 5 (Indemnification), 6 (Disclaimer), 7 (Limitation of Liability), and this Section 8.5 will survive any termination of this Agreement.
                            </p>
                        </section>

                        {/* Section 9: General */}
                        <section className="text-sm pt-4 px-6 border rounded-lg bg-white">
                            <h2 className="text-2xl font-semibold text-[#8A79EC] mb-4">9. General</h2>

                            <h3 className="text-lg font-semibold mb-2">9.1 Ownership</h3>
                            <p className="text-gray-700 mb-4">
                                Tarn and its licensors retain all ownership rights in and to the Platform Services, including all intellectual property rights therein. Customer’s rights to use the Platform Services are limited to those expressly granted in this Agreement. No other rights are granted to Customer by implication or otherwise.
                            </p>

                            <h3 className="text-lg font-semibold mb-2">9.2 Marketing</h3>
                            <p className="text-gray-700 mb-4">
                                Customer grants Tarn the right to include Customer’s name and logo in lists of Tarn’s customers on Tarn’s website and marketing materials, subject to Customer’s written approval (including email consent).
                            </p>

                            <h3 className="text-lg font-semibold mb-2">9.3 Notices</h3>
                            <p className="text-gray-700 mb-4">
                                All notices under this Agreement must be in writing and sent to the receiving party’s principal place of business or to such other address as the receiving party may designate. Notices may be delivered by email, overnight courier, or registered mail.
                            </p>

                            <h3 className="text-lg font-semibold mb-2">9.4 Governing Law</h3>
                            <p className="text-gray-700 mb-4">
                                This Agreement is governed by the laws of the state of Delaware, United States, without regard to its conflict of law principles. Any disputes arising from this Agreement will be resolved in the state or federal courts located in Wilmington, Delaware, and the parties consent to the jurisdiction of these courts.
                            </p>

                            <h3 className="text-lg font-semibold mb-2">9.5 Force Majeure</h3>
                            <p className="text-gray-700 mb-4">
                                Neither party will be liable for any failure or delay in performance under this Agreement due to causes beyond its reasonable control, including acts of God, labor disputes, or governmental actions. Each party will use reasonable efforts to resume performance as soon as feasible following such an event.
                            </p>

                            <h3 className="text-lg font-semibold mb-2">9.6 No Assignment</h3>
                            <p className="text-gray-700 mb-4">
                                Neither party may assign this Agreement or any of its rights or obligations hereunder without the other party’s prior written consent, except in connection with a merger, acquisition, or sale of substantially all assets. Any unauthorized assignment is void.
                            </p>

                            <h3 className="text-lg font-semibold mb-2">9.7 Entire Agreement</h3>
                            <p className="text-gray-700 mb-4">
                                This Agreement constitutes the entire agreement between the parties regarding the subject matter hereof and supersedes all prior or contemporaneous agreements, understandings, or communications. Any modifications to this Agreement must be in writing and signed by both parties.
                            </p>

                            <h3 className="text-lg font-semibold mb-2">9.8 Severability</h3>
                            <p className="text-gray-700 mb-4">
                                If any provision of this Agreement is found to be unenforceable, that provision will be reformed to the extent necessary to make it enforceable, and all other provisions will remain in effect.
                            </p>

                            <h3 className="text-lg font-semibold mb-2">9.9 Waiver</h3>
                            <p className="text-gray-700 mb-4">
                                Failure to enforce any right or provision of this Agreement will not constitute a waiver of future enforcement of that right or provision.
                            </p>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TermsConditions;