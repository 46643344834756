import React from 'react';
import LottieAnimation from '../ui/LottieAnimation.js'; // Adjust the path as needed
import LottieAnimationSmall from '../ui/LottieAnimationSmall.js'; // Adjust the path as needed

function ProductBrands() {
  const scrollToProductHero = () => {
    const targetSection = document.getElementById('product-hero');
    if (targetSection) {
      const yOffset = 80; // Adjust this value for further down (negative for above, positive for below)
      const yPosition = targetSection.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: yPosition, behavior: 'smooth' });
    }
  };

  return (
    <div className="flex justify-center items-center lg:mt-24 md:mt-16 mt-12 lg:space-x-28 md:space-x-12 space-x-2 lg:px-4 md:px-4 px-4">
      {/* tarnData */}
      <div
        className="border border-indigo-300 rounded-lg flex flex-col justify-between items-center lg:h-56 w-64 shadow-lg cursor-pointer"
        onClick={scrollToProductHero} // Updated click handler
      >
        {/* Logo Section */}
        <div className="flex flex-col items-center lg:px-5 px-3">
          <img
            src="/assets/Data_Gradient.png"
            alt="Data Gradient"
            className="lg:h-32 h-28 lg:pb-0 md:pb-0 pb-10 object-contain"
          />
        </div>
        <div className="h-6 flex items-center">
          <p className="text-center mb-14 lg:pt-10 md:pt-10 sm:pt-8 text-xs md:text-sm lg:text-sm text-gray-900 font-medium">
            A Salesforce admin's best friend
          </p>
        </div>
        {/* Conditionally Render LottieAnimation */}
        <div>
          {/* Small Animation: Always visible */}
          <div className="block md:hidden">
            <LottieAnimationSmall visible={true} />
          </div>
          {/* Large Animation: Visible on medium and larger screens */}
          <div className="hidden md:block">
            <LottieAnimation visible={true} />
          </div>
        </div>
      </div>

      {/* tarnChat */}
      <div className="border rounded-lg flex flex-col justify-between items-center lg:h-56 w-64 opacity-50">
        {/* Logo Section */}
        <div className="flex flex-col items-center lg:px-5 px-3">
          <img
            src="/assets/Chat_Black.png"
            alt="Chat Gradient"
            className="lg:h-32 h-28 lg:pb-0 md:pb-0 pb-10 object-contain"
          />
        </div>
        {/* Text Section */}
        <div className="flex flex-col items-center">
          <div className="h-6 flex items-center">
            <p className="text-center lg:mb-32 mb-16 lg:pt-10 md:pt-10 sm:pt-8 text-xs md:text-sm lg:text-sm text-gray-900 font-medium">
              Chat with your Salesforce
            </p>
          </div>
          <div className="h-6 flex items-center">
            <p className="text-center mb-1 text-xxs md:text-sm lg:text-sm italic text-gray-400">
              Coming Winter 2025
            </p>
          </div>
        </div>
      </div>

      {/* tarnDash */}
      <div className="border rounded-lg flex flex-col justify-between items-center lg:h-56 w-64 opacity-50">
        {/* Logo Section */}
        <div className="flex flex-col items-center lg:px-5 px-3">
          <img
            src="/assets/Dash_Black.png"
            alt="Reporter Gradient"
            className="lg:h-32 h-28 lg:pb-0 md:pb-0 pb-10 object-contain"
          />
        </div>
        {/* Text Section */}
        <div className="flex flex-col items-center">
          <div className="h-6 flex items-center">
            <p className="text-center lg:mb-32 mb-16 lg:pt-10 md:pt-10 sm:pt-8 text-xs md:text-sm lg:text-sm text-gray-900 font-medium">
              Beautiful dashboards in seconds
            </p>
          </div>
          <div className="h-6 flex items-center">
            <p className="text-center mb-1 text-xxs md:text-sm lg:text-sm italic text-gray-400">
              Coming Winter 2025
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductBrands;