import { useState } from 'react';
import { Bars3Icon } from '@heroicons/react/24/outline';
import NavigationMenu from './NavigationMenu';
import MobileMenu from './MobileMenu';

function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header className="absolute inset-x-0 top-0 z-50">
      <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1">
          {/* Tarn logo redirects to https://tarn.co/ */}
          <a href="https://tarn.co/" className="-m-1.5 p-1.5">
            <span className="sr-only">Tarn Homepage</span>
            <img className="h-8 w-auto" src="/assets/tarn_logo_purple.png" alt="Tarn Logo" />
          </a>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-600"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          <NavigationMenu />
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          {/* Login redirects to https://app.tarn.co/ */}
          <a
            href="https://app.tarn.co/"
            className="pt-2 text-sm font-semibold leading-6 text-gray-500 hover:text-indigo-500 transition-colors"
          >
            Login <span aria-hidden="true">&rarr;</span>
          </a>
        </div>
      </nav>
      <MobileMenu mobileMenuOpen={mobileMenuOpen} setMobileMenuOpen={setMobileMenuOpen} />
    </header>
  );
}

export default Header;