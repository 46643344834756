import React, { useState, useEffect } from 'react';
import Lottie from 'react-lottie';

const LottieAnimation = ({ visible }) => {
  const [animationData, setAnimationData] = useState(null);

  useEffect(() => {
    fetch('https://lottie.host/1f5708f5-3e4c-4c23-aed0-c83d4825e480/DHr8QrmWbu.json')
      .then(response => response.json())
      .then(data => setAnimationData(data));
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  if (!animationData || !visible) {
    return null; // or a loading spinner
  }

  return (
    <div style={{ transform: '', width: '60px', height: '50px' }}>
      <Lottie options={defaultOptions} />
    </div>
  );
};

export default LottieAnimation;