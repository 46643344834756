import React from 'react';
import BackgroundGradient from '../ui/BackgroundGradient.jsx'; // Import the BackgroundGradient component
import StackedBackgroundGradient from '../ui/StackedBackgroundGradient.jsx';
import ProductHero from './ProductHero.jsx'; // Import the ProductHero component
import HowItWorks from './HowItWorks.jsx';

export default function Product() {
  return (
    <div className="relative isolate">
      <StackedBackgroundGradient />
      <ProductHero />
      <HowItWorks />
    </div>
  );
}