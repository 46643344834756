import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid';

export default function Step1Rules() {
    return (
        <div id="step-1-rules" className="relative isolate overflow-hidden mt-28">
            <div className="mx-auto max-w-7xl lg:px-6">
                <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                    {/* Left Side: Heading, Paragraph, and Features */}
                    <div>
                        <h2 className="text-4xl font-semibold tracking-tight text-gray-900">1. Create a <span className ="text-indigo-600">Rule</span></h2>
                        <p className="mt-8 text-lg font-medium text-gray-500 sm:text-xl">
                            Find the records you need to fix. <br /> Write a prompt as if you're describing a Salesforce report.
                        </p>
                        <dl className="mt-10 space-y-8 text-base/7 text-gray-600">
                            {/* Feature 1 */}
                            <div className="relative pl-9">
                                <dt className="inline font-semibold text-gray-900">
                                    <ServerIcon
                                        aria-hidden="true"
                                        className="absolute left-1 top-1 h-6 w-6 text-indigo-600"
                                    />
                                    Find records in <span className="text-indigo-600 font-bold">seconds.</span>
                                </dt>{' '}
                                <dd className="inline">
                                    Create sophisticated rules in seconds with a prompt. Whether you're tackling a
                                    recurring data issue or implementing a new field, rules make it easy to find the
                                    records you're looking for.
                                </dd>
                            </div>

                            {/* Feature 2 */}
                            <div className="relative pl-9">
                                <dt className="inline font-semibold text-gray-900">
                                    <CloudArrowUpIcon
                                        aria-hidden="true"
                                        className="absolute left-1 top-1 h-6 w-6 text-indigo-600"
                                    />
                                    Standard or Custom Objects.
                                </dt>{' '}
                                <dd className="inline">
                                    Create a rule on any standard or custom Salesforce object. We learn your
                                    object configurations at login.
                                </dd>
                            </div>

                            {/* Feature 3 */}
                            <div className="relative pl-9">
                                <dt className="inline font-semibold text-gray-900">
                                    <LockClosedIcon
                                        aria-hidden="true"
                                        className="absolute left-1 top-1 h-6 w-6 text-indigo-600"
                                    />
                                    Standard or Custom Fields.
                                </dt>{' '}
                                <dd className="inline">
                                    All standard and custom 'filterable' fields are supported. We prioritize the fields
                                    you care about from your page layouts.
                                </dd>
                            </div>
                        </dl>
                    </div>

                    {/* Right Side: Screenshot */}
                    <div className="flex items-center justify-center">
                        <img
                            alt="Product screenshot"
                            src="/assets/create-a-rule.gif"
                            width={1216} // Half the original width
                            height={721}
                            className="rounded-xl shadow-xl ring-1 ring-gray-400/10"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}