const navigation = [
  { name: 'Product', href: '/product' },
  { name: 'Pricing', href: '/pricing' },
  { name: 'Team', href: '/team' },
  { name: 'Try it', href: 'https://app.tarn.co/' },
];

function NavigationMenu({ direction = 'horizontal' }) {
  const isVertical = direction === 'vertical';

  return (
    <nav
      className={isVertical ? 'space-y-2' : 'flex gap-x-16'}
      aria-label="Navigation Menu"
    >
      {navigation.map((item) => (
        <a
          key={item.name}
          href={item.href}
          className="pt-2 text-sm font-semibold leading-6 text-gray-500 hover:text-indigo-500 transition-colors"
        >
          {item.name}
        </a>
      ))}
    </nav>
  );
}

export default NavigationMenu;