import React from 'react';
import { CheckCircleIcon, ArrowRightIcon } from '@heroicons/react/24/outline'; // Import necessary icons
import BackgroundGradient from './ui/BackgroundGradient.jsx'; // Import the BackgroundGradient component

function Testing() {
  const sections = [
    {
      title: 'How to get set up',
      content: [
        {
          type: 'numbered-list',
          items: [
            {
              text: 'Go to ',
              link: {
                text: 'developer.salesforce.com/signup',
                href: 'https://developer.salesforce.com/signup',
                target: '_blank',
              },
              afterText: ' and sign up for a free Salesforce instance.',
            },
            {
              text: 'Salesforce will send you an email verification, then it will start setting up your instance - this can take a few minutes.',
            },
            {
              text: 'Once you’ve successfully logged in to Salesforce, go to ',
              link: {
                text: 'app.tarn.co',
                href: 'https://app.tarn.co',
                target: '_blank',
              },
              afterText: ' and log in with Salesforce. You’re good to go!',
            },
          ],
        },
      ],
    },
    {
      title: 'Where to start',
      content: [
        {
          type: 'paragraph',
          text: 'Start by generating some data to work with in your Salesforce instance. Your Salesforce instance may or may not start out with data, which you can check by creating a rule for “All Accounts” or “All Contacts” etc.',
        },
        {
          type: 'paragraph',
          text: 'If you need more data, or any data, click on the Account under Salesforce Objects on the left. You will see a button appear that says Generate Test Data in the header. This button uses OpenAI to create realistic fake data for the Salesforce Object you have selected.',
        },
        {
          type: 'paragraph',
          text: 'Once you’ve created some Account data, the Contact and Opportunity data you generate will be automatically tied to one of the Accounts that’s been generated.',
          boldWords: ['Account', 'Contact', 'Opportunity'],
        },
        {
          type: 'image',
          src: 'https://res.cloudinary.com/dnhbo3j62/image/upload/v1727486018/Screenshot_2024-09-27_at_9.11.26_PM_lgs7qh.png',
        },
      ],
    },
    {
      title: 'What to test',
      content: [
        {
          type: 'paragraph',
          text: 'Everything, but specifically:',
        },
        {
          type: 'list',
          items: [
            {
              text: 'Generating Rules',
              bold: true,
              afterText: ' - go wild and think of any Salesforce report you can imagine. Remember, tarnData only works on one Salesforce object at a time, so any report that crosses over Salesforce Objects is not supported (yet ;)',
            },
            {
              text: 'Generating Fixes',
              bold: true,
              afterText: ' - this is really the meat of the product, and testing combinations of formula (no AI) and AI Fixes for Issues would be greatly appreciated',
            },
            {
              text: 'Running Fixes',
              bold: true,
              afterText: ' - did this actually fix your data?',
            },
            {
              text: 'Accepting Fixes',
              bold: true,
              afterText: ' - did these successfully update in Salesforce?',
            },
          ],
        },
        {
          type: 'image',
          src: 'https://res.cloudinary.com/dnhbo3j62/image/upload/v1727487146/Screenshot_2024-09-27_at_9.31.24_PM_zzpfxt.png',
        },
      ],
    },
    {
      title: 'How to give Feedback',
      content: [
        {
          type: 'paragraph',
          text: 'The easiest way for you (and for me!) to give feedback is in the app itself. There are three ways to give feedback.',
        },
        {
          type: 'list',
          items: [
            {
              text: 'On the Create Rule overlay box, there’s a button that says “Not what you expected?” This submission automatically includes the generated rule and your prompt, so just tell me what you expected',
            },
            {
              text: 'On the Create Fix overlay box, there is the same button. This also includes the Fix and the prompt with your submission.',
            },
            {
              text: 'General Feedback - there’s a submit feedback button in the header for any and all feedback. Please include screenshots!',
            },
          ],
        },
        {
          type: 'image-comparison',
          leftImage: 'https://res.cloudinary.com/dnhbo3j62/image/upload/v1727485832/Screenshot_2024-09-27_at_9.00.03_PM_uyakrw.png',
          rightImage: 'https://res.cloudinary.com/dnhbo3j62/image/upload/v1727485394/Screenshot_2024-09-27_at_9.00.11_PM_rxdvbq.png',
        },
      ],
    },
  ];

  // Function to render content based on type
  const renderContent = (content) => {
    return content.map((item, index) => {
      if (item.type === 'paragraph') {
        return (
          <p key={index} className="mb-4 text-gray-700">
            {/* Render text and links if present */}
            {item.text.split(' ').map((word, idx) => {
              if (item.link && word.includes(item.link.text)) {
                return (
                  <a
                    key={idx}
                    href={item.link.href}
                    className="text-blue-500 underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.link.text}
                  </a>
                );
              }
              return word + ' ';
            })}
          </p>
        );
      } else if (item.type === 'list') {
        return (
          <ul key={index} className="list-disc ml-8 mt-2 mb-4 text-gray-700">
            {item.items.map((listItem, idx) => (
              <li key={idx} className="mb-2">
                {typeof listItem === 'string' ? (
                  listItem
                ) : listItem.bold ? (
                  <>
                    <strong>{listItem.text}</strong>
                    {listItem.afterText}
                  </>
                ) : listItem.boldWords ? (
                  highlightWords(listItem.text, listItem.boldWords)
                ) : (
                  listItem.text
                )}
              </li>
            ))}
          </ul>
        );
      } else if (item.type === 'numbered-list') {
        return (
          <ol key={index} className="list-decimal ml-8 mt-2 mb-4 text-gray-700">
            {item.items.map((listItem, idx) => (
              <li key={idx} className="mb-2">
                {listItem.text}
                {listItem.link && (
                  <a
                    href={listItem.link.href}
                    className="text-blue-500 underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {listItem.link.text}
                  </a>
                )}
                {listItem.afterText}
              </li>
            ))}
          </ol>
        );
      } else if (item.type === 'image') {
        return (
          <div key={index} className="flex justify-center mt-8">
            <img
              src={item.src}
              alt="Screenshot"
              className="rounded-md w-full sm:w-full md:w-2/3 lg:w-2/3 border border-gray-300"
            />
          </div>
        );
      } else if (item.type === 'image-comparison') {
        return (
          <div
            key={index}
            className="flex justify-center items-center mt-8 space-x-6"
          >
            {/* Left Image */}
            <img
              src={item.leftImage}
              alt="Feedback Example Left"
              className="rounded-md w-1/3 border border-gray-300"
            />
            {/* Arrow Icon */}
            <ArrowRightIcon className="h-12 w-12 text-indigo-500" />
            {/* Right Image */}
            <img
              src={item.rightImage}
              alt="Feedback Example Right"
              className="rounded-md w-1/3 border border-gray-300"
            />
          </div>
        );
      }
      return null;
    });
  };

  // Function to bold specific words in a text
  const highlightWords = (text, wordsToBold) => {
    const parts = text.split(new RegExp(`(${wordsToBold.join('|')})`, 'g'));
    return parts.map((part, index) =>
      wordsToBold.includes(part) ? (
        <strong key={index}>{part}</strong>
      ) : (
        <span key={index}>{part}</span>
      )
    );
  };

  return (
    <div className="relative isolate pt-14">
      {/* Background gradient */}
      <BackgroundGradient />

      <div className="py-24 px-2 md:px-14 lg:px-14">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900">
              tarnData Testing
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              First of all, thank you very much for any time you’re willing to spend testing tarnData.
              I’m proud of where I'm at so far, but there’s still plenty of work to be done.
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              You might want to{' '}
              <a
                href="https://www.youtube.com/watch?v=Jz_HBpRqW40"
                className="text-blue-500 underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                watch this 10 min demo
              </a>{' '}
              before testing. Apologies for the duration, but you can watch it at 2x speed 🙂

            </p>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              The demo is based on version 0.9.0. There have been new releases since then - check out the{' '}
              <a
                href="https://tarn.co/release-notes"
                className="text-blue-500 underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                release notes
              </a>
              !
            </p>
          </div>

          {/* Rendering sections */}
          <div className="mt-10 space-y-14">
            {sections.map((section, index) => (
              <div
                key={index}
                className="text-sm pt-4 px-6 border rounded-lg bg-white shadow-md"
              >
                <div className="w-full flex items-center mb-4">
                  <CheckCircleIcon className="h-6 w-6 text-green-500 mr-2" />
                  <h2 className="text-2xl font-semibold text-[#8A79EC]">
                    {section.title}
                  </h2>
                </div>
                <div className="mb-8 text-gray-700 pt-3">
                  {renderContent(section.content)}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testing;