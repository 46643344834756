export default function ProductHero() {
    return (
      <div id="product-hero" className="relative isolate overflow-hidden">
        <div className="mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-32 lg:flex lg:px-8 lg:pt-40 md:pt-32 pt-28">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:shrink-0 lg:pt-8">
            <h1 className="text-pretty text-5xl font-semibold tracking-tight text-gray-900 sm:text-7xl">
              Better Data, <br /> Better Decisions.
            </h1>
            <p className="mt-8 text-pretty text-lg font-medium text-gray-500 sm:text-xl/8">
              <span className="block sm:inline">AI-powered data management.</span>{' '}
              <span className="text-indigo-600">Set up in seconds.</span> <br />
              Never see an empty Salesforce field again.
            </p>
            <div className="mt-10 flex items-center gap-x-6">
              <a
                href="https://app.tarn.co/"
                className="rounded-md bg-indigo-600 px-6 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Try it
              </a>
              <a
                href="#how-it-works"
                className="text-sm/6 font-semibold text-gray-900"
                onClick={(e) => {
                  e.preventDefault();
                  document
                    .getElementById('how-it-works')
                    .scrollIntoView({ behavior: 'smooth' });
                }}
              >
                How it works <span aria-hidden="true">↓</span>
              </a>
            </div>
          </div>
          <div className="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-32">
            <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
              <div className="-m-2 rounded-xl bg-gray-900/5 p-2 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl lg:p-4">
                <img
                  alt="App screenshot"
                  src="https://res.cloudinary.com/dnhbo3j62/image/upload/v1732635195/Screenshot_2024-11-26_at_10.33.05_AM_jme8ou.png"
                  width={2432}
                  height={1442}
                  className="w-[76rem] rounded-md shadow-2xl ring-1 ring-gray-900/10"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }